<template>
  <div class="box">
    <SATMenu v-if="false" active="1" />
    <div class="container">
      <div class="sat">
        <h1 class="sat_left">
          <p v-if="isNew">New Digital SAT</p>
          <p v-else>Old Paper-Based SAT</p>
        </h1>
        <div class="change-version">
          <el-button
            v-if="isNew === 1"
            @click="changeVersion(0)"
            style="width:auto"
            ><i class="el-icon-refresh"></i>
            {{ $t("sat.switch_old") }}</el-button
          >
          <el-button
            v-if="isNew === 0"
            @click="changeVersion(1)"
            style="width:auto"
            ><i class="el-icon-refresh"></i>
            {{ $t("sat.switch_new") }}</el-button
          >
        </div>
      </div>
      <hr />
      <SATType :type="practiceType"></SATType>
      <ListTitle :testType="testType" :type="practiceType" :subjects="subjects" :isNew="isNew" />
      <hr />
      <el-form ref="form" @submit.native.prevent>
        <el-form-item>
          <el-input
            ref="filterInput"
            placeholder="Name"
            v-model="search.search"
            @keyup.enter.native="searchResult"
          >
            <el-button
              el-button
              slot="append"
              icon="el-icon-search"
              @click="searchResult"
            >
              Search
            </el-button>
          </el-input>
        </el-form-item>
      </el-form>
      <el-alert v-if="resultsTotal > -1" type="success" :closable="false">
        <span slot="title">
          Results: <b>{{ resultsTotal }}</b>
        </span>
      </el-alert>
      <div v-if="practices">
        <div class="text-center" style="margin:15px 0">
          <el-pagination
            background
            @current-change="setPage"
            :page-size="pageSize"
            :current-page="page"
            :total="resultsTotal"
            :pager-count="9"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
        <el-table
          v-if="practices.length > 0"
          :data="practices"
          style="width: 100%"
          stripe
        >
          <el-table-column label="Name" min-width="170">
            <template slot-scope="scope">
              <b>
                {{ getCompanyExamTitle( scope.row.exam.title) }}
              </b>
            </template>
          </el-table-column>
          <el-table-column label="Questions" width="100">
            <template slot-scope="scope">
              <b class="text-success">{{
                scope.row.exam.question_count
              }}</b>
            </template>
          </el-table-column>
          <el-table-column label="Source" width="100" v-if="false">
            <template slot-scope="scope">
              <b>SATMocks</b>
            </template>
          </el-table-column>
          <el-table-column label="Cost" width="150">
            <template slot-scope="scope">
              <template v-if="isPro || isAdmin">
                <b class="originCoin mr-2">
                  {{ scope.row.exam.coin_plans[0].coin }}
                  {{
                    scope.row.exam.coin_plans[0].coin > 1
                      ? $t("sat.Coins")
                      : $t("sat.Coin")
                  }}
                  <span class="removeLine"></span>
                </b>
                <el-tooltip
                  v-if="isAdmin"
                  class="item"
                  effect="dark"
                  content="Admin"
                  placement="top"
                >
                  <b class="text-success">
                    <i class="fas fa-building"></i>
                    Free
                  </b>
                </el-tooltip>
                <el-tooltip
                  v-else-if="isPro"
                  class="item"
                  effect="dark"
                  content="Pro"
                  placement="top"
                >
                  <b class="admin-Pro" style="color: orange;">
                    <i class="fas fa-crown"></i> Free
                  </b>
                </el-tooltip>
              </template>
              <b v-else>
                {{ scope.row.exam.coin_plans[0].coin }}
                {{
                  scope.row.exam.coin_plans[0].coin > 1
                    ? $t("sat.Coins")
                    : $t("sat.Coin")
                }}
              </b>
            </template>
          </el-table-column>
          <el-table-column
            width="130"
            label="Actions"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-tooltip
                class="mr-2"
                effect="dark"
                content="Start a test"
                placement="top"
              >
                <router-link
                  :to="{
                    name: isPhone ? 'SATTestLockPage' : 'SATTestLockPage',
                    params: {
                      practiceType: 'singlePractice',
                      id: scope.row.id
                    }
                  }"
                >
                  <el-button type="success" size="mini">
                    <i class="fas fa-play"></i>
                  </el-button>
                </router-link>
              </el-tooltip>
              <el-tooltip
                class="mr-2"
                effect="dark"
                content="Browse"
                placement="top"
                v-if="isAdmin || isTeacher"
              >
                <router-link
                  :to="{
                    name: routerLink,
                    query: { id: scope.row.exam.test_id }
                  }"
                >
                  <el-button type="success" size="mini">
                    <i class="fas fa-eye"></i>
                  </el-button>
                </router-link>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <div v-else>
          <el-empty :description="$t('courses.empty-text')"></el-empty>
        </div>
        <div class="text-center" style="margin:15px 0">
          <el-pagination
            background
            @current-change="setPage"
            :page-size="pageSize"
            :current-page="page"
            :total="resultsTotal"
            :pager-count="9"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
        <el-alert
          type="info"
          class="mt-2 mb-4"
          show-icon
          :closable="false"
          style="text-align:left"
        >
          <span>
            Need more practices? Want a particular type of drill practices?
          </span>
          <a @click="goToFeedbackPage" style="cursor: pointer;">
            <b>Let us know </b>
          </a>
          <span>and we'll create them for you!</span>
        </el-alert>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SATMenu from "@/views/satList/components/SATMenu.vue";
import ListTitle from "@/views/satList/components/PracticesTitle";
import PracticeCard from "@/views/satList/components/PracticeCard";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";
import Theme from "@/common/theme";
import SATType from "@/views/satList/components/SATType";
import { instant } from "@ivy-way/material";
import role from "@/mixins/role.js";

export default {
  metaInfo() {
    return {
      title: "SAT Practices - " + this.CompanyName
    };
  },

  components: {
    SATMenu,
    ListTitle,
    SATType
  },

  mixins: [Common, role],

  props: [],
  data() {
    return {
      practices: null,
      resultsTotal: 0,
      pageSize: 0,
      subjects: [],
      search: {
        search: null,
        source: null
      }
    };
  },
  computed: {
    routerLink() {
      if (this.practiceType === "composePractices") {
        return "SatComposePracticeDetailAll";
      } else if (this.practiceType === "adaptivePractices") {
        return "SatAdaptiveDetailAll";
      } else {
        return "SatPracticeDetailAll";
      }
    },
    isPro() {
      return this.$store.getters["user/getIsPro"];
    },
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    isTeacher() {
      let isTeacher = this.isRoleTeacher();
      return isTeacher;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    instant() {
      return instant;
    },
    practiceType() {
      if (this.$route.query.type) {
        return this.$route.query.type;
      } else {
        return "Drill Practice";
      }
    },
    isNew() {
      let isNew = 1;
      if (this.$route.query.isNew === undefined) {
        isNew = 1;
      } else {
        isNew = parseInt(this.$route.query.isNew);
      }
      return isNew;
    },
    searchString() {
      return this.$route.query.search ? this.$route.query.search : null;
    },
    ...mapState("user", ["token", "lang"]),
    isLogin() {
      return this.token !== "";
    },
    testType() {
      let testType = "english";
      if (this.$route.query.testType) {
        testType = this.$route.query.testType;
      }
      return testType;
    },
    subjectsNow() {
      const OLD_PRACTICE_READING = 1;
      const OLD_PRACTICE_WRITING = 2;
      const OLD_PRACTICE_MATH = 3;
      const OLD_PRACTICE_MATH_CALC = 4;
      const OLD_DRILL_READING = 5;
      const OLD_DRILL_WRITING = 6;
      const OLD_DRILL_MATH = 7;
      const OLD_DRILL_MATH_CALC = 8;
      const OLD_FULL_TEST = 9;
      const OLD_ADAPTIVE_PRACTICE = 10;

      const DRILL_PRACTICE_ENGLISH = 11;
      const DRILL_PRACTICE_MATH = 12;
      const MODULE_PRACTICE_ENGLISH = 13;
      const MODULE_PRACTICE_MATH = 14;
      const FULL_TEST = 15;
      const ADAPTIVE_PRACTICE = 16;
      let subjects = [];
      if (this.practiceType == "Drill Practice") {
        if (this.isNew === 1) {
          subjects = [
            { id: 11, name: "english" },
            { id: 12, name: "math" }
          ];
        } else {
          subjects = [
            { id: 5, name: "reading" },
            { id: 6, name: "writing" },
            { id: 7, name: "math" },
            { id: 8, name: "math calculator" }
          ];
        }
      } else {
        if (this.isNew === 1) {
          subjects = [
            { id: 13, name: "english" },
            { id: 14, name: "math" }
          ];
        } else {
          subjects = [
            { id: 1, name: "reading" },
            { id: 2, name: "writing" },
            { id: 3, name: "math" },
            { id: 4, name: "math calculator" }
          ];
        }
      }
      return subjects;
    },
    subjectId() {
      let subjectId = this.subjectsNow[0].id;
      if (this.$route.query.subject_id) {
        subjectId = this.$route.query.subject_id;
      }
      return subjectId;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    },
    theme() {
      return Theme.name;
    }
  },
  watch: {},

  async mounted() {
    if (this.isLogin) {
      const subjects = await SAT.getSubjects();
      this.subjects = subjects.sat_subjects;
      let res = null;
      this.search.search = this.searchString;
      res = await SAT.getPractices({
        page: this.page,
        is_public: 1,
        keyword: this.getSourceTitle(this.search.search),
        category: this.subjectId
        // is_draft: this.searchTest.is_draft
      });
      this.practices = res.practices.data;
      this.resultsTotal = res.practices.total;
      this.pageSize = res.practices.per_page;
    } else {
      if (this.theme === "SAT") {
        this.$router.push({ name: "Login" });
      }
    }
  },

  methods: {
    searchResult() {
      console.log(this.search);
      this.$router.replace({
        query: {
          ...this.$route.query,
          ...this.search,
          page: 1
        }
      });
    },
    async goToFeedbackPage() {
      window.open(
        "mailto:satmocks@gmail.com?subject=Requesting Practice Tests",
        "_blank"
      );
    },
    changeVersion(val) {
      let testType = "reading";
      let subject_id = 1;
      if (val === 1) {
        testType = "english";
        subject_id = 5;
      }
      this.$router.replace({
        name: this.$route.name,
        query: {
          isNew: val,
          testType,
          subject_id
        }
      });
    },
    setPage(page) {
      this.routerPush({
        name: this.$route.name,
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    }
  }
};
</script>

<style scoped>
::v-deep .el-table__header th,
::v-deep .el-table th.el-table__cell {
  background: #f5f7fa;
}
.originCoin {
  font-size: 16px;
  color: #aaa;
  position: relative;
  line-height: 0.8;
  font-weight: 700;
}
.removeLine {
  border-top: 2px solid #aaa;
  position: absolute;
  left: 0px;
  top: 12px;
  transform: rotate(-6deg);
  width: 50px;
}
.sat {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
}
.box .container {
  padding-top: 0;
}

.sat_left {
  margin: 20px 0;
  display: flex;
  color: var(--themeColor);
  text-decoration: none;
}

.sat_left > span {
  font-weight: 700;
  font-size: 20px;
}
.sat_left > p {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 0px;
}
.create-compose-practice {
  margin-left: 10px;
  font-size: 60%;
  line-height: 50px;
}
@media screen and (max-width: 768px) {
  .box .container {
    padding: 0 15px 20px 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .sat {
    display: block;
  }
  .sat_left {
    margin: 0;
    margin-top: 0;
  }
  .sat_left > p {
    font-weight: 700;
    font-size: 30px;
  }
}
</style>
