<template>
  <div>
    <hr class="mt-1 mb-1" v-if="isPhone" />
    <div style="padding:0 ;margin-right: -15px;margin-left: -15px;">
      <div class="btn-list">
        <template>
          <template v-if="subjectsNow && subjectsNow.length > 0">
            <div
              :class="`btn-item-box`"
              v-for="(subject,index) in subjectsNow"
              :key="index"
            >
              <div :class="theme === 'SAT' ? 'btn-item-type' : 'btn-item-type'">
                <a @click="selectType(subject.name, subject.id)">
                  <label
                    :class="
                      testType == subject.name
                        ? `btn active ${subject.name}`
                        : `btn ${subject.name}`
                    "
                  >
                    {{ titleCase(subject.name) }}
                  </label>
                </a>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Common from "@/mixins/common.js";
import Theme from "@/common/theme";
import role from "@/mixins/role.js";

export default {
  components: {},

  mixins: [Common, role],

  props: ["testType", "type", "subjects", "isNew"],
  data() {
    return {};
  },
  computed: {
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    theme() {
      return Theme.name;;
    },
    // subjectsNow() {
    //   let subjects = [];
    //   if (this.isNew === 1) {
    //     subjects = [this.subjects[4], this.subjects[2]];
    //   } else {
    //     subjects = [
    //       this.subjects[0],
    //       this.subjects[1],
    //       this.subjects[2],
    //       this.subjects[3]
    //     ];
    //   }
    //   return subjects;
    // }
    subjectsNow() {
      const OLD_PRACTICE_READING = 1;
      const OLD_PRACTICE_WRITING = 2;
      const OLD_PRACTICE_MATH = 3;
      const OLD_PRACTICE_MATH_CALC = 4;
      const OLD_DRILL_READING = 5;
      const OLD_DRILL_WRITING = 6;
      const OLD_DRILL_MATH = 7;
      const OLD_DRILL_MATH_CALC = 8;
      const OLD_FULL_TEST = 9;
      const OLD_ADAPTIVE_PRACTICE = 10;

      const DRILL_PRACTICE_ENGLISH = 11;
      const DRILL_PRACTICE_MATH = 12;
      const MODULE_PRACTICE_ENGLISH = 13;
      const MODULE_PRACTICE_MATH = 14;
      const FULL_TEST = 15;
      const ADAPTIVE_PRACTICE = 16;
      let subjects = [];
      if (this.type == "Drill Practice") {
        if (this.isNew === 1) {
          subjects = [
            { id: 11, name: "english" },
            { id: 12, name: "math" }
          ];
        } else {
          subjects = [
            { id: 5, name: "reading" },
            { id: 6, name: "writing" },
            { id: 7, name: "math" },
            { id: 8, name: "math calculator" }
          ];
        }
      } else {
        if (this.isNew === 1) {
          subjects = [
            { id: 13, name: "english" },
            { id: 14, name: "math" }
          ];
        } else {
          subjects = [
            { id: 1, name: "reading" },
            { id: 2, name: "writing" },
            { id: 3, name: "math" },
            { id: 4, name: "math calculator" }
          ];
        }
      }
      return subjects;
    }
  },
  watch: {},

  mounted() {},

  methods: {
    selectType(testType, subject_id) {
      let name = "SATList";
      this.$router.replace({
        name: name,
        query: {
          ...this.$route.query,
          testType: testType,
          subject_id: subject_id,
          page: 1
        }
      });
    }
  }
};
</script>

<style scoped>
.ivy-type-title {
  font-size: 15px;
  font-weight: bold;
  height: 35px;
  line-height: 35px;
  margin: 10px 0;
}
.btn-list {
  display: flex;
  /* justify-content: space-between; */
}
.btn-item-box {
  width: 25%;
  padding: 0 15px;
}
.btn-item {
  width: 200px;
}
.btn-item-type {
  width: 100%;
}
.btn-item-type-ivyway {
  width: 100%;
}
.btn {
  width: 100%;
  height: 80px;
  line-height: 80px;
  cursor: pointer;
  background-color: #f5f5f5;
  color: var(--themeColor);
  text-align: center;
  border-radius: 5px;
  padding: 0 5px;
  margin: 10px 0;
  display: block;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #edf1f8;
  color: #808080;
  transition: 0.2s 0s;
  box-shadow: 0 5px 35px 0 rgb(83 120 186 / 15%);
  background: url(https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/icons/p4.svg)
    no-repeat right bottom #ffffff;
  background-size: 23% 50%;
}
.math {
  background: url(https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/icons/dltl.png)
    no-repeat right bottom #ffffff;
  background-size: 20% 60%;
}
.calculator {
  background: url(https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/icons/jcsx.png)
    no-repeat right bottom #ffffff;
  background-size: 20% 60%;
}
.english {
  background: url(https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/icons/gre_b21.png)
    no-repeat right bottom #ffffff;
  background-size: 20% 60%;
}
.reading {
  background: url(https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/icons/ydylj.png)
    no-repeat right bottom #ffffff;
  background-size: 20% 60%;
}
.writing {
  background: url(https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/icons/ijd.png)
    no-repeat right bottom #ffffff;
  background-size: 20% 60%;
}

.btn-ivyway .btn {
  box-shadow: none;
  background: none;
  background-color: #edf1f8;
}
.btn:hover,
.active {
  color: white;
  background-color: var(--themeColor);
  box-shadow: 1px 3px 6px #aaa;
}
.ivy-type-title {
  display: block;
}
.toefl-sections-xs {
  display: none;
}
.toefl-sections-content-xs .btn-item {
  padding: 0 5px;
}
.toefl-sections-content-xs {
  display: flex;
}
@media screen and (max-width: 992px) {
  .ivy-type-title {
    text-align: center !important;
    height: 25px;
    line-height: 25px;
    margin: 0;
  }
  .d-xs-none {
    display: none;
  }
  .btn-list {
    display: block;
  }
  .btn-item-type,
  .btn-item-box,
  .btn-item {
    width: 100%;
  }
  .btn-item-box {
    padding: 0 15px;
  }
  .ivy-type-title {
    display: none;
  }

  .toefl-sections {
    display: none;
  }

  .toefl-sections-xs {
    display: block;
    width: 100%;
  }

  .toefl-sections-xs > .toefl-sections-header-xs {
    display: block;
    width: 100%;
    height: 35px;
    line-height: 35px;
    background-color: var(--themeColor);
    color: #fff;
    text-align: center;
    border-radius: 5px 5px 0px 0px;
    padding: 0 5px;
    margin-top: 15px;
  }

  .toefl-sections-xs > .toefl-sections-content-xs {
    height: 60px;
    /* border: 1px solid #dcdcdc; */
    border-radius: 0px 0px 5px 5px;
    display: flex;
    padding: 0px 9px;
  }

  .toefl-sections-xs > .toefl-sections-content-xs > .btn-item {
    padding: 0px 6px;
  }
  .math {
    background: url(https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/icons/dltl.png)
      no-repeat right bottom #ffffff;
    background-size: 10% 60%;
  }
  .calculator {
    background: url(https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/icons/jcsx.png)
      no-repeat right bottom #ffffff;
    background-size: 10% 60%;
  }
  .english {
    background: url(https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/icons/gre_b21.png)
      no-repeat right bottom #ffffff;
    background-size: 10% 60%;
  }
  .reading {
    background: url(https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/icons/ydylj.png)
      no-repeat right bottom #ffffff;
    background-size: 10% 60%;
  }
  .writing {
    background: url(https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/icons/ijd.png)
      no-repeat right bottom #ffffff;
    background-size: 10% 60%;
  }
  .btn.active {
    background-color: var(--themeColor);
  }
}
</style>
